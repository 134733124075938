<template>
  <ObjectiveForm
      v-if="loaded"
      :id="id"
      :objective="objective"
      :in-modal="false"
      :edit="true"
  ></ObjectiveForm>
</template>

<script>

import ObjectiveForm from "@/components/objectives/ObjectiveForm";
import Objective from "@/models/Objective";

export default {
  name: 'ObjectiveEdit',
  props: ["id"],
  data() {
    return {
      loaded: false,
    }
  },
  components: {
    ObjectiveForm,
  },
  computed: {
    objective() {
      return Objective.query().whereId(parseInt(this.id)).first()
    }
  },
  mounted() {
    this.$store.dispatch('loader/show')
    Objective.FetchById(this.id).then(() => {
      this.$store.dispatch('loader/hide')
      this.loaded = true
    }).catch(err => {
      this.$store.dispatch('loader/hide')
      this.handleError(err)
    })
  }

};
</script>
