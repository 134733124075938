import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import List from "@ckeditor/ckeditor5-list/src/list";
import Table from "@ckeditor/ckeditor5-table/src/table";
// import MathType from '@wiris/mathtype-ckeditor5';


export let mathEditorConfig = {
    plugins: [
        // MathType,
        EssentialsPlugin,
        Underline,
        Alignment,
        BoldPlugin,
        ItalicPlugin,
        LinkPlugin,
        ParagraphPlugin,
        BlockQuote,
        List,
        Table,
    ],
    toolbar: [
        "bold",
        "italic",
        "underline",
        // "|",
        // "Mathtype",
        "|",
        "alignment",
        "link",
        "bulletedList",
        "numberedList",
        "insertTable",
        "blockquote",
        "|",
        "undo",
        "redo",
    ],
}
