<template>
  <form @submit.prevent="submit">
    <ValidationObserver ref="observer">

      <div :class="{'modal-card':inModal}">
        <header v-if="inModal" class="modal-card-head">
          <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} Objective</p>
        </header>
        <PerfectScrollbarWrapper>
          <section :class="{'modal-card-body':inModal}">
            <b-field label="Objective Reference">
              <b-input
                  v-model="objectiveObject.reference"
                  placeholder="Objective Reference"
                  required>
              </b-input>
            </b-field>
            <ValidationProvider
                v-slot="{ errors }"
                rules="required"
            >
              <b-field v-if="showEditor" type="is-danger" :message="errors.length>0?'This field is required':''" label="Content" style="overflow: hidden">
                <ckeditor v-model="objectiveObject.name" :config="editorConfig" :editor="editor"></ckeditor>
              </b-field>
            </ValidationProvider>
            <ValidationProvider
                v-slot="{ errors }"
                rules="required"
            >
            <b-field type="is-danger" :message="errors.length>0?'This field is required':''" v-if="showEditor" label="Recommended Activities and Resources" style="overflow: hidden">
              <ckeditor v-model="objectiveObject.recommended_activities" :config="editorConfig"
                        :editor="editor"></ckeditor>
            </b-field>
            </ValidationProvider>

            <p v-if="this.edit" class="has-text-weight-semibold mt-5 is-size-4">Uploads:</p>

            <div v-if="this.edit" class="columns is-multiline is-fullwidth">
              <b-field class="column is-12">
                <div class="columns is-centered">
                  <div class="column is-12 has-text-centered">
                    <b-upload v-model="dropFile" :disabled="!this.edit || isLoading" drag-drop>
                      <section class="section">
                        <div class="content has-text-centered">
                          <p>
                            <b-icon
                                :icon="$tc('icons.upload')"
                                size="is-large"
                            ></b-icon>
                          </p>
                          <p>Drop your file here or click to upload</p>
                          <p>Max file size of 10mb</p>
                        </div>
                      </section>
                    </b-upload>
                  </div>
                </div>
              </b-field>
              <div v-if="dropFile" class="upload-file-list column">
                <div class="notification">
                  <div class="level">
                    <div class="level-left">
                      <div class="level-item">
                        <form @submit.prevent="submitMedia">
                          <p class="is-flex is-align-items-center">
                            <b-icon
                                custom-size="default"
                                :icon="$tc('icons.upload')"
                            ></b-icon>
                            <span>{{ dropFile.name }}</span></p>
                          <b-field>
                            <b-field>
                              <b-input
                                  v-model="dropFile.caption"
                                  name="caption"
                                  placeholder="Describe the upload"
                              />
                            </b-field>
                          </b-field>

                          <b-field>
                            <b-field grouped>
                              <div class="control">
                                <b-button
                                    native-type="submit"
                                    type="is-primary"
                                >Submit upload
                                </b-button
                                >
                              </div>
                            </b-field>
                          </b-field>
                        </form>
                      </div>
                    </div>
                    <div class="level-right">
                      <div class="level-item">
                        <a
                            class="tag is-delete"
                            @click.prevent="deleteDropFile()"
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <b-table
                v-if="this.edit"
                :bordered="true"
                :data="media"
                :hoverable="true"
                :striped="true"
                class="margin-top"
            >
              <template #empty>
                <div class="has-text-centered">No Media</div>
              </template>
              <b-table-column v-slot="props"

                              field="filename"
                              label="Filename"
                              sortable
              >{{ props.row.filename }}
              </b-table-column
              >


              <b-table-column v-slot="props"
                              field="caption"
                              label="Caption"
                              sortable
              >{{
                  props.row.caption
                }}
              </b-table-column
              >

              <b-table-column v-slot="props" custom-key="actions" width="170">
                <b-field grouped>
                  <b-field>
                    <b-button
                        :href="props.row.temporary_url"
                        tag="a"
                        target="_blank"
                        type="is-primary">View
                    </b-button>

                  </b-field>
                  <b-field style="height: fit-content; margin: auto">
                    <b-tooltip
                        label="Delete"
                        size="is-small"
                        type="is-danger">
                      <a
                          class="card-header-icon has-text-danger px-0 py-0"
                          @click.prevent.stop="startDelete(props.row)">
                        <b-icon :icon="$tc('icons.delete')"/>
                      </a>
                    </b-tooltip>
                  </b-field>
                </b-field>

              </b-table-column>
            </b-table>
          </section>
        </PerfectScrollbarWrapper>

        <b-loading v-model="isLoading" :can-cancel="false" :is-full-page="false"></b-loading>

        <footer v-if="canEdit" :class="{'modal-card-foot':inModal}">
          <b-field grouped>
            <b-field v-if="inModal">
              <b-button @click="$emit('close')">Cancel</b-button>
            </b-field>
            <b-field>
              <b-button :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)" native-type="submit"
                        type="is-primary">
                Save
              </b-button>
            </b-field>
          </b-field>
        </footer>
      </div>
    </ValidationObserver>

  </form>
</template>

<script>

import Objective from "@/models/Objective";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import {mathEditorConfig} from "@/mathEditorConfig";
import Media from "@/models/Media";
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {required} from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This field is required",
});

export default {
  components: {PerfectScrollbarWrapper, ValidationProvider, ValidationObserver},
  name: "ObjectiveForm",
  data() {
    return {
      isLoading: false,
      dropFile: null,

      editor: ClassicEditor,
      editorConfig: {
        ...mathEditorConfig,
        ...{placeholder: "Objective body"},
      },
      showEditor: false,
      objectiveObject: {
        reference: null,
        name: '',
        recommended_activities: null,
      }
    }
  },
  computed: {
    media() {
      return Media.query().get()
    },
  },
  methods: {
    submitMedia() {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("file", this.dropFile);
      formData.append("caption", this.dropFile.caption);

      Media.Store({model: 'objectives', model_id: this.objective.id}, formData)
          .then(() => {
            this.dropFile = null
            this.isLoading = false
            // Media.FetchAll(this.application_details.id, "applications").then(
            //     () => {
            //       this.dropFile = null;
            //       this.isLoading = false;
            //     }
            // );
          }).catch(
          err => {
            this.isLoading = false;

            if (err.response.status === 422) {
              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err.response.data),
              });
            } else {
              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err),
              });
            }
          }
      )
    },

    deleteDropFile() {
      this.dropFile = null
    },
    startDelete(media) {
      this.$buefy.dialog.confirm({
        title: 'Deleting resource',
        confirmText: 'Delete Resource',
        hasIcon: true,
        type: 'is-danger',
        message: 'Are you sure you want to delete this resource?',
        onConfirm: () => this.delete(media)
      })
    },
    delete(media) {
      this.isLoading = true
      Media.Delete(media.id).then(() => {
        this.$buefy.snackbar.open('Resource deleted!')
        this.isLoading = false
      }).catch(
          err => {
            this.isLoading = false;

            if (err.response.status === 422) {
              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err.response.data),
              });
            } else {
              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err),
              });
            }
          }
      )
    },

    submit() {
      this.$refs.observer.validate().then((valid) => {
        if (!valid) {
          return;
        }
        if (this.edit) {
          Objective.Update(this.objectiveObject).then(() => {
            this.$buefy.snackbar.open(`Objective updated!`)
            this.$emit('close')
          }).catch(err => {
            this.handleError(err)
          })
        } else {
          Objective.Store(this.objectiveObject).then(response => {
            this.$buefy.snackbar.open(`Objective created!`)
            this.$emit('submit', response.entities.objectives[0])
            this.$emit('close')
          }).catch(err => {
            this.handleError(err)
          })
        }
      })
    }
  },
  mounted() {
    Media.deleteAll()

    if (this.edit) {
      let promises = []
      this.objectiveObject = JSON.parse(JSON.stringify(this.objective))
      if (this.edit) {
        promises.push(Media.FetchAll({page: 1, limit: 30}, {modelName: 'objectives', model: this.objective.id}))
      }
      Promise.all([promises]).then(() => {
        this.lessonObject = this.lessonPlan

        this.isLoading = false
        this.showEditor = true


      })

    }
    this.showEditor = true
  },
  props: {

    canEdit: {
      type: Boolean,
      default() {
        return true
      }
    },
    campus_id: {
      type: Number, default() {
        return 0
      }
    },
    objective: {
      type: Object,
      default() {
        return {
          name: '', reference: ''
        }
      },
    },
    inModal: {
      type: Boolean, default() {
        return false
      }
    },
    edit: {
      type: Boolean, default() {
        return false
      }
    },
  }
}
</script>

